<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="primaryent">
        <!-- <header class="header">
            <div class="center_title">
                <div class="header_left">
                <img class="logo" src="../../assets/logo.png" @click="home" alt>
                <img class="text" src="../../assets/text2.png" @click="home" alt>
                </div>
                <div class="header_right">
                <img class="phone" src="../../assets/phone.png" alt>
                <p class="phone_text">公司电话 1008611</p>
                </div>
            </div>
        </header> -->
        <heade></heade>
        <div class="con">
            <div class="box">
                <img src="../../assets/duigou.png" alt="">
                <p class="p1">主管理员{{name}}已经添加成功</p>
                <p class="p2">将以短信形式通知管理员手机中</p>
                <div>
                    <p class="p4" @click="jix">确定</p>
                </div>
            </div>
        </div>
        <foote class="foote"></foote>
    </div>
</template>

<script>
import foote from '../../components/Foote'
import heade from '../../components/Heade'
export default {
  name: 'primaryent',
  data () {
    return {
      name: ''
    }
  },
  created () {
    if (sessionStorage.getItem('pc_guanli_name') != null && sessionStorage.getItem('pc_guanli_name') != undefined) {
      this.name = JSON.parse(sessionStorage.getItem('pc_guanli_name'))
    }
  },
  components: { foote, heade },
  methods: {
    jix () {
      this.$router.push('/master')
    },
    home () {
      this.$router.push('/enterprise')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.primaryent {
    background:#E7E8EB;
    height: 100vh;
    .header {
        position: fixed;
        top: 0;
        height: 0.8rem;
        width: 100%;
        background-color: #f5f5f5;
        .center_title {
            margin: 0 auto;
            height: 100%;
            width: 12rem;
            background-color: #f5f5f5;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .header_left {
                display: flex;
                align-items: center;
                .logo {
                height: 0.33rem;
                width: 0.35rem;
                }
                .text {
                height: 0.18rem;
                width: 0.75rem;
                margin-left: 0.05rem;
                margin-right: 0.23rem;
                }
                cursor:pointer;
            }
            .header_right {
                display: flex;
                align-items: center;
                .phone {
                height: 0.18rem;
                width: 0.17rem;
                margin-right: 0.05rem;
                }
                .phone_text {
                font-size: 0.14rem;
                color: #3c3a3e;
                }
            }
        }
        @media screen and (max-width: 12rem) {
            .center_title{
                width: 100%;
            }
        }
    }
    .con{
        width: 100%;
        height:85.2%;
        background:#E7E8EB;
        display: flex;
        align-items: center;
        .box{
            height:5.1rem;
            width:9.59rem;
            margin:0 auto;
            background: #fff;
            img{
                height: 0.8rem;
                width: 0.8rem;
                display: block;
                margin:0 auto;
                margin-top:0.81rem;
                margin-bottom: 0.4rem;
            }
            .p1{
                font-size: 0.24rem;
                color:#1A2533;
                font-weight: 500;
                text-align: center;
                margin-bottom: 0.14rem;
            }
            .p2{
                font-size: 0.14rem;
                color:#888888;
                text-align: center;
            }
            div{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top:0.93rem;
                p{
                    height:0.45rem;
                    width:1.79rem;
                    border-radius: 0.06rem;
                    color:#fff;
                    font-size: 0.22rem;
                    font-weight: bold;
                    line-height: 0.45rem;
                    text-align: center;
                }
                .p4{
                    background: #F96C6C;
                }
            }
        }
        @media screen and (max-width: 9.59rem) {
            .box{
                width: 95%;
            }
        }
    }
    .foote {
        background-color: #2a3041;
        height: 1.43rem;
        width: 100%;
        display: flex;
        align-items: center;
        a{
            margin: 0 auto;
            color:#fff;
            font-size: 0.12rem;
        }
    }
}
</style>
